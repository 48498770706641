import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Dialog, Divider, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { closeVerifyEmailModal } from '../../actions/modals'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent, getNewGuest } from '../../selectors/event'
import FlatButton from '../../shared-components/buttons/FlatButton'
import WizardTextField from './WizardTextField'
import apiClient from '../../shared-components/utils/ApiClient'
import { setCurrentStep, showConfetti } from '../../actions/rsvp'
import { setSnackbar } from '../../shared-components/redux/notifications/actions'
import { fetchUser } from 'actions/user'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    paddingBottom: theme.spacing(2),
    fontWeight: 700,
  },
  divider: {
    background: '1px solid ' + theme.palette.grey.main,
    margin: theme.spacing(0, -3),
  },
  caption: {
    margin: theme.spacing(2, 0, 1, 0),
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  resendButton: {
    color: theme.palette.text.secondary,
    marginRight: 'auto',
    marginLeft: '-6px',
    marginBottom: theme.spacing(2),
  },
  codeField: {
    margin: theme.spacing(0.5, 0, '2px', 0),
  },
  cancelButton: {},
  sendButton: {
    flex: 1,
  },
  email: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
  },
}))

const VerifyEmailDialogOld = ({}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const { classes } = useStyles()
  const open = useSelector((state) => state.modals.open.verifyEmailOld)
  const event = useSelector(getCurrentEvent)
  const newGuest = useSelector(getNewGuest)
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [invalidCode, setInvalidCode] = useState(false)
  const [count, setCount] = useState(15)

  const inputRef = useRef()

  useEffect(() => {
    const countDown = () => {
      if (count > 0) {
        setCount((prev) => prev - 1)
        setTimeout(() => {
          countDown()
        }, 1000)
      }
    }
    setTimeout(() => {
      countDown()
    }, 1000)
  }, [])

  useEffect(() => {
    setCount(15)
  }, [open])

  const onClose = () => {
    dispatch(closeVerifyEmailModal())
  }

  const onConfirm = async () => {
    setLoading(true)
    try {
      const result = await apiClient.user.verifyCode(
        newGuest.emailAddress,
        code,
      )
      dispatch({
        type: 'FETCH_USER_FULFILLED',
        payload: result,
      })
      const fetchedEvent = await apiClient.event.byCode(event.code)
      dispatch({
        type: 'FETCH_EVENT_FULFILLED',
        payload: fetchedEvent,
      })

      // Load user for mixpanel
      dispatch(fetchUser())
      onClose()
      // if (!result.hasAvatar) {
      //   dispatch(setCurrentStep('profile'))
      // }
      dispatch(setCurrentStep('app'))
      dispatch(setSnackbar('success', t('emailVerified')))
      if (newGuest.status === 'GOING') {
        dispatch(showConfetti())
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const onResend = () => {
    onClose()
  }

  const onChangeCode = useCallback((event) => {
    setCode(event.target.value)
  }, [])

  return (
    <Dialog
      classes={{ paper: classes.root }}
      aria-labelledby='confirm-action-dialog'
      onClose={onClose}
      open={open}
    >
      <Typography className={classes.title} variant='h6'>
        {t('verifyEmail.title')}
      </Typography>

      <Divider className={classes.divider} />

      <Typography variant='body1' className={classes.caption}>
        {t('verifyEmail.caption')}
      </Typography>
      <Typography
        className={classes.email}
        color='textSecondary'
        variant='body1'
      >
        {newGuest?.emailAddress}
      </Typography>

      <WizardTextField
        full
        placeholder={t('verifyEmail.hint')}
        value={code}
        onChange={onChangeCode}
        error={invalidCode}
        errorText={t('verifyEmail.error')}
        autoFocus
        className={classes.codeField}
        onSubmit={onConfirm}
        inputRef={inputRef}
      />

      <Button
        className={classes.resendButton}
        disabled={count > 0}
        onClick={onResend}
      >
        {t('verifyEmail.resend') + (count > 0 ? ` (${count.toString()})` : '')}
      </Button>

      <div className={classes.buttonsContainer}>
        <FlatButton
          color='grey'
          onClick={onClose}
          className={classes.cancelButton}
        >
          {t('cancel')}
        </FlatButton>
        <FlatButton
          className={classes.sendButton}
          disabled={code === ''}
          loading={loading}
          onClick={onConfirm}
          color='primary'
        >
          {t('verifyEmail.send')}
        </FlatButton>
      </div>
    </Dialog>
  )
}

export default VerifyEmailDialogOld
